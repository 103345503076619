<template>
  <b-popover :target="targetId" triggers="hover" delay="200" :placement="popupSide" custom-class="rounded-lg mw-100 w-auto ml-1">
    <div
      class="d-flex p-75"
      :style="{
        gap: '1rem',
        backgroundColor: '#090909',
        flexDirection: filteredOptions.length > 3 ? 'column' : 'row',
      }"
    >
      <span v-for="(option, index) in filteredOptions" :key="option" class="d-flex align-items-center" style="gap: 1rem">
        <span class="d-flex align-items-center" style="gap: 0.5rem">
          <font-awesome-icon :icon="getIcon(option)" :class="getClass(option)" style="width: 1.1rem; height: 1.1rem" />
          <span class="text-muted">{{ renderCount(option) }} {{ title(option) }}</span>
        </span>
        <span v-if="filteredOptions.length <= 3 && index < filteredOptions.length - 1">|</span>
      </span>
    </div>
  </b-popover>
</template>

<script>
import { BPopover } from 'bootstrap-vue';
import { gradeScore, title } from '@core/utils/filter';

export default {
  components: {
    BPopover,
  },
  props: {
    data: { type: Object, required: true },
    targetId: { type: String, required: true },
    popupSide: { type: String, default: 'right' },
  },
  data() {
    return {
      statusOptions: [
        'total',
        'created',
        'pending',
        'action_required',
        'enrolled',
        'withdrew',
        'withdrew_s1',
        'withdrew_s2',
        'withdrew_s3',
        'terminated',
        'graduated',
        'denial_of_benefit',
        'leave_of_absence',
        'archived',
        'completed',
        'credited',
        'reassigned',
        'replaced',
      ],
    };
  },
  computed: {
    filteredOptions() {
      return this.statusOptions.filter((option) => this.data.counts.byStatus[option] !== undefined);
    },
  },
  methods: {
    title,
    gradeScore,
    getIcon(option) {
      switch (option) {
        case 'total':
          return 'fa-regular fa-circle';
        case 'enrolled':
          return 'fa-regular fa-signature';
        case 'graduated':
          return 'fa-regular fa-graduation-cap';
        case 'completed':
          return 'fa-regular fa-check-circle';
        case 'credited':
          return 'fa-regular fa-sack-dollar';
        case 'reassigned':
          return 'fa-regular fa-magnifying-glass-arrows-rotate';
        case 'replaced':
          return 'fa-regular fa-rotate';
        case 'withdrew':
        case 'withdrew_s1':
        case 'withdrew_s2':
        case 'withdrew_s3':
          return 'fa-regular fa-arrow-right-from-bracket';
        case 'terminated':
          return 'fa-regular fa-circle-xmark';
        default:
          return 'fa-regular fa-circle';
      }
    },
    getClass(option) {
      switch (option) {
        case 'total':
          return 'text-primary';
        case 'enrolled':
          return 'text-info';
        case 'graduated':
          return 'text-success';
        case 'completed':
          return 'text-success';
        case 'credited':
          return 'text-success';
        case 'reassigned':
          return 'text-warning';
        case 'replaced':
          return 'text-warning';
        case 'withdrew':
        case 'withdrew_s1':
        case 'withdrew_s2':
        case 'withdrew_s3':
          return 'text-warning';
        case 'terminated':
          return 'text-danger';
        default:
          return 'text-muted';
      }
    },
    renderCount(option) {
      return this.data.counts.byStatus[option];
    },
  },
};
</script>
