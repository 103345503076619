<template>
  <div class="d-flex align-items-center" style="width: 100%; gap: 0.675rem">
    <div style="max-width: 10.5rem; min-width: 7rem; width: 100%">
      <v-select
        :value="visibilityFilter"
        :options="visibilityOptions"
        :placeholder="'Visibility'"
        class="drop-shadow-default"
        :reduce="(val) => val.value"
        @input="(val) => $emit('update:visibilityFilter', val)"
      />
    </div>
    <div style="max-width: 10.5rem; min-width: 7rem; width: 100%">
      <v-select
        :value="statusFilter"
        :options="statusOptions"
        :placeholder="'Status'"
        class="drop-shadow-default"
        :reduce="(val) => val.value"
        @input="(val) => $emit('update:statusFilter', val)"
      />
    </div>
  </div>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol, BCardText } from 'bootstrap-vue';
import vSelect from 'vue-select';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardActions,
    BCardText,
    vSelect,
  },
  props: {
    visibilityFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    visibilityOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
};
</script>
